"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _Fb = _interopRequireDefault(require("@meepshop/context/lib/Fb"));

// import
// definition
var _default = function _default(href, fbRef) {
  var _useContext = (0, _react.useContext)(_Fb["default"]),
      fb = _useContext.fb;

  var isLoadedRef = (0, _react.useRef)(false);
  (0, _react.useEffect)(function () {
    if (!fb || !fbRef.current || !fbRef.current.parentNode) return;

    if (!isLoadedRef.current) {
      fbRef.current.removeAttribute('fb-xfbml-state');
      fbRef.current.removeAttribute('fb-iframe-plugin-query');
    }

    isLoadedRef.current = true;
    fb.XFBML.parse(fbRef.current.parentNode);
  }, [href, fbRef, fb]);
};

exports["default"] = _default;