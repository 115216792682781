"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/web.timers.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _emptyFunction = _interopRequireDefault(require("fbjs/lib/emptyFunction"));

// typescript import
// import
// definition
var RATIO_TO_VALUE = {
  Ratio16to9: 9 / 16,
  Ratio4to3: 3 / 4,
  Ratio16to10: 10 / 16
};

var _default = function _default(ratio) {
  var _useState = (0, _react.useState)(0),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      height = _useState2[0],
      setHeight = _useState2[1];

  var videoRef = (0, _react.useRef)(null);
  var resizeTimeoutRef = (0, _react.useRef)(setTimeout(_emptyFunction["default"], 0));
  var isMountedRef = (0, _react.useRef)(false);
  (0, _react.useEffect)(function () {
    var resize = function resize() {
      clearTimeout(resizeTimeoutRef.current);
      resizeTimeoutRef.current = setTimeout(function () {
        if (!videoRef.current) return; // @ts-ignore FIXME: react player type error

        setHeight(videoRef.current.wrapper.offsetWidth * RATIO_TO_VALUE[ratio]);
      }, 100);
    };

    if (!isMountedRef.current) resize();
    window.addEventListener('resize', resize);
    isMountedRef.current = true;
    return function () {
      clearTimeout(resizeTimeoutRef.current);
      window.removeEventListener('resize', resize);
    };
  }, [ratio]);
  return {
    height: height,
    videoRef: videoRef
  };
};

exports["default"] = _default;